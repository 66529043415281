var comingsoon = function ComingsoonViewModel () {
  var self = this

  self.dismiss = function () {
    setCookie('comingsoon', true, 7)
  }

  self.action = function () {
    if(!trovacigusto.userLogged()){
      window.location.href = '/register'
      //trovacigusto.modalRegister.show()
    }else{
      if(trovacigusto.userLogged().catering){
        window.location.href='/catering/'+trovacigusto.userLogged().catering.id+'/admin/invite'
      }
      else{
        window.location.href='/user/favorite/caterings#locali'
      }
    }
  }

/*  function countdownCoomingsoon(date) {
    var endDate = moment(date, ['DD.MM.YYYY', 'DD-MM-YYYY']);
    var eventTime = endDate.unix();
    var currentTime = moment().unix();
    var diffTime = eventTime -currentTime;

    dateCountdown(diffTime, '.countdown-coming', 'd [Giorni e] hh[h] mm[m] ss[s]');
  }

  countdownCoomingsoon('01.01.2023');*/

  return self
}
$(document).ready(function () {
  if (document.getElementById('comingsoon')) {
    ko.applyBindings(new comingsoon(), document.getElementById('comingsoon'))

    if (getCookie('comingsoon')) {
      document.getElementById('comingsoon').style.display = 'none'
    } else {
      document.getElementById('comingsoon').style.display = null
      //@TRELLO [10369]
      if ($(window).width() < 991) {
        $('#main').css('margin-top', '142px')
        $('.nav-up').css('top', '0')
      } else
        $('#main').css('margin-top', '100px')

      $('#comingsoon').on('closed.bs.alert', function () {
        if ($(window).width() < 991) {
          $('#main').css('margin-top', '90px')
          $('.nav-up').css('top', '-50px')
        } else {
          $('#main').css('margin-top', '50px')
        }
      })
    }
  }
})