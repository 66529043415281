function TopBarViewModel() {
    var self = this;

    self.searchMethod = ko.observable('caterings'); //caterings or geo

    self.openSearch = function () {
        trovacigusto.modalSearch.search();
    };

    self.searchBarPlaceholder= isMobile()|| isGonative() ?'TrovaciGusto...':'Cerca...';

    return self;
}

if (document.getElementById("navbar-menu") && !topBar) {
    var topBar = new TopBarViewModel();
    ko.applyBindings(topBar, document.getElementById("navbar-menu"));
}