var menuExtra = function MenuExtraViewModel() {
    var self = this;
    self.searchType = ko.observable((typeof TROVACIGUSTO !== 'undefined' && TROVACIGUSTO.target) ? TROVACIGUSTO.target : 'offers');
    self.permanentFilters = (typeof TROVACIGUSTO !== 'undefined' && TROVACIGUSTO.filters) ? TROVACIGUSTO.filters : [];

    self.openFiltersXs = function () {
        searchResult.openFiltersXs(true);
    };
    self.openSortFiltersXs = function () {
        searchResult.openSortFiltersXs(true);
    };
    self.openSearchTypeXs = function () {
        searchResult.openSearchTypeXs(true);
    };
    self.openFiltersUsersXs = function () {
        searchResult.openFiltersUsersXs(true);
    };

    self.searchOffers= function(){
     searchResult.searchOffers()
    }

    self.searchCaterings= function(){
        searchResult.searchCaterings()
    }

    self.sectionName = ko.observable("Proposte");
    if (self.searchType()) {
        switch (self.searchType()) {
            case "offers":
                if (self.permanentFilters['nonprofit'])
                    self.sectionName("Noprofit");
                else
                    self.sectionName("Proposte");
                break;
            case "caterings":
                if (self.permanentFilters['supplier'])
                    self.sectionName("Fornitori");
                else
                    self.sectionName("Attività");
                break;
            case "users":
                self.sectionName("Utenti");
                break;
            case "noprofit":
                break;
            default:
                self.sectionName("Proposte");
                break;
        }
    }

    return self;
};